import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    loginState: false
  },
  mutations: {
    setLoginState (state, loginState) {
      state.loginState = loginState
    }
  },
  getters: {
    loginState: state => state.loginState
  },
  actions: {

  },
  modules: {

  }
})
