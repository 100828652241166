<template>
  <div id="app">
    <router-view></router-view>

<!--    <Rabbit></Rabbit>-->
  </div>
</template>
<script>
import Rabbit from '@/components/rabbit'
var Base64 = require('js-base64').Base64
export default {
  components: {
    // Rabbit
  },
  data () {
    return {}
  },
  watch: {
    $route (to, from) {
      const tokenStr = window.localStorage.getItem('token')
      if (tokenStr) {
        const role = JSON.parse(window.localStorage.getItem('user')).relepers
        const allMenu = JSON.parse(Base64.decode(role))
        if (this.checkRouter(allMenu, to.name)) { // 存在
          // console.log('存在')
        } else {
          if (to.name === 'userinfo' || to.name === 'message') {
          } else {
            this.$router.push({ path: '/error' })
          }
        }
      }
    }
  },
  mounted () {},
  methods: {
    checkRouter (route, path) { // 多维数组判断是否存在某值
      let res
      (function deepId (route, path) {
        for (let a of route) {
          if (a.path === path) {
            res = a
          } else if (a.children && !res) {
            deepId(a.children, path)
          }
        }
      })(route, path)
      return res
    }
  }
}
</script>
<style lang="less">
  body,html{margin: 0px;}
  html, body, #app {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    //background: #f0f0f0;
    overflow: hidden;
  }
  body {
    font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, 'STHeiTi', sans-serif;
  }
  a {
    text-decoration: none;
  }
  * {
    outline: none;
  }
  li {
    list-style: none;
  }
  #app {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .contInput{
    width: 180px;
    margin-right: 16px;
  }
</style>
