import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/Login',
    name: 'Login',
    component: () => import('../views/login/login.vue')
  },
  {
    path: '/',
    redirect: '/main'
  },
  {
    path: '/main',
    name: 'main',
    redirect: '/management',
    component: () => import('../views/main.vue'),
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('../views/home/index.vue')
      },
      {
        path: '/management',
        name: 'management',
        component: () => import('../views/management/index.vue')
      },
      {
        path: '/rotation',
        name: 'rotation',
        component: () => import('../views/rotation/index.vue')
      },
      {
        path: '/customerList',
        name: 'customerList',
        component: () => import('../views/customer/customerList.vue')
      },
      {
        path: '/customerGroup',
        name: 'customerGroup',
        component: () => import('../views/customer/customerGroup.vue')
      },
      {
        path: '/staff',
        name: 'staff',
        component: () => import('../views/staff/index.vue')
      },
      {
        path: '/department',
        name: 'department',
        component: () => import('../views/department/index.vue')
      },
      {
        path: '/role',
        name: 'role',
        component: () => import('../views/role/index.vue')
      },
      {
        path: '/marketList',
        name: 'marketList',
        component: () => import('../views/marketing/marketList.vue')
      },
      {
        path: '/marketGroup',
        name: 'marketGroup',
        component: () => import('../views/marketing/marketGroup.vue')
      },
      {
        path: '/rebate',
        name: 'rebate',
        component: () => import('../views/marketing/rebate.vue')
      },
      {
        path: '/vouchers',
        name: 'vouchers',
        component: () => import('../views/marketing/vouchers.vue')
      },
      {
        path: '/recharge',
        name: 'recharge',
        component: () => import('../views/marketing/recharge.vue')
      },
      {
        path: '/commodityList',
        name: 'commodityList',
        component: () => import('../views/commodity/commodityList.vue')
      },
      {
        path: '/commoditySort',
        name: 'commoditySort',
        component: () => import('../views/commodity/commoditySort.vue')
      },
      {
        path: '/commodityBrand',
        name: 'commodityBrand',
        component: () => import('../views/commodity/commodityBrand.vue')
      },
      {
        path: '/commodityUnit',
        name: 'commodityUnit',
        component: () => import('../views/commodity/commodityUnit.vue')
      },
      {
        path: '/serveList',
        name: 'serveList',
        component: () => import('../views/service/serveList.vue')
      },
      {
        path: '/serveLabel',
        name: 'serveLabel',
        component: () => import('../views/service/serveLabel.vue')
      },
      {
        path: '/serveGroup',
        name: 'serveGroup',
        component: () => import('../views/service/serveGroup.vue')
      },
      {
        path: '/finance',
        name: 'finance',
        component: () => import('../views/finance/index.vue')
      },
      {
        path: '/storeList',
        name: 'storeList',
        component: () => import('../views/store/storeList.vue')
      },
      {
        path: '/storeCosmet',
        name: 'storeCosmet',
        component: () => import('../views/store/storeCosmet.vue')
      },
      {
        path: '/storeKpi',
        name: 'storeKpi',
        component: () => import('../views/store/storeKpi.vue')
      },
      {
        path: '/afterAssess',
        name: 'afterAssess',
        component: () => import('../views/after/afterAssess.vue')
      },
      {
        path: '/afterRefund',
        name: 'afterRefund',
        component: () => import('../views/after/afterRefund.vue')
      },
      {
        path: '/order',
        name: 'order',
        component: () => import('../views/order/index.vue')
      },
      {
        path: '/receptionBill',
        name: 'receptionBill',
        component: () => import('../views/reception/receptionBill.vue')
      },
      {
        path: '/receptionCashier',
        name: 'receptionCashier',
        component: () => import('../views/reception/receptionCashier.vue')
      },
      {
        path: '/receptionCard',
        name: 'receptionCard',
        component: () => import('../views/reception/receptionCard.vue')
      },
      {
        path: '/receptionOrder',
        name: 'receptionOrder',
        component: () => import('../views/reception/receptionOrder.vue')
      },
      {
        path: '/company',
        name: 'company',
        component: () => import('../views/company/index.vue')
      },
      {
        path: '/version',
        name: 'version',
        component: () => import('../views/system/version.vue')
      },
      {
        path: '/log',
        name: 'log',
        component: () => import('../views/system/log.vue')
      },
      {
        path: '/message',
        name: 'message',
        component: () => import('../views/message/index.vue')
      },
      {
        path: '/userinfo',
        name: 'userinfo',
        component: () => import('../views/userinfo/index.vue')
      },
      {
        path: '/error',
        name: 'error',
        component: () => import('../views/error/error')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  scrollBehavior: () => ({ x: 0, y: 0 }),
  routes
})

// 全局路由守卫
router.beforeEach((to, from, next) => {
  // to: Route: 即将要进入的目标 路由对象
  // from: Route: 当前导航正要离开的路由
  // next: Function: 一定要调用该方法来 resolve 这个钩子。执行效果依赖 next 方法的调用参数。
  const isLogin = window.localStorage.getItem('token') // 是否登录
  const nextRoute = ['home']
  // 未登录状态；当路由到nextRoute指定页时，跳转至login
  if (nextRoute.indexOf(to.name) >= 0) {
    if (!isLogin) {
      return next('/Login')
    }
  }
  // 已登录状态;当路由到login时,跳转至home[动态路由跟着变化]
  if (to.name === 'login') {
    if (isLogin) {
      return next('/main')
    }
  }
  next()
})

const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
