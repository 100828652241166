<template>

</template>

<script>
import Stomp from 'stompjs'
export default {
  name: 'rabbit',
  data () {
    return {
      state: '',
      client: null
    }
  },
  computed: {
    loginState () {
      return this.$store.getters.loginState
    }
  },
  watch: {
    loginState (n, m) {
      var state = window.sessionStorage.getItem('State')
      if (state === '1') {
        this.connect()
      } else {
        this.close()
      }
    }
  },
  created () {
    this.state = window.sessionStorage.getItem('State')
    if (this.state === '1') {
      this.connect()
    } else {
      this.close()
    }
  },
  methods: {
    connect () {
      // const ws = new WebSocket('wss://47.98.119.187:15674/ws')
      const ws = new WebSocket('wss://api.dazhihui029.com:7443/ws')
      this.client = Stomp.over(ws)
      const headers = {
        login: 'dzh',
        passcode: 'dzhadmin',
        host: '/',
        ack: 'client'
      }
      this.client.connect(headers, this.onConnected, this.onFailed)
    },
    onConnected: function (frame) { // 订阅频道
      const _staff = JSON.parse(window.localStorage.getItem('user')).staffid
      const topic = '/exchange/weinan/00583358a11911ecbfea0242ac120005' // 渭南
      // const topic = '/exchange/yuchen_exchange/alarm_server'
      this.client.subscribe(topic, this.responseCallback, { ack: 'client' })
    },
    onFailed: function (frame) {
      console.log('连接失败')
      this.connect()
    },
    responseCallback: function (frame) {
      frame.ack()
      console.log('MQ msg=>' + frame.body)
    },
    close: function () {
      const that = this
      if (that.client !== null) {
        that.client.disconnect(function () {
          console.log('已断开链接')
          that.client = null
          return false
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
