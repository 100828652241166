// 路径地址
// 视频 - 图片路径地址
const PATH = {
  // 附件提交
  annex: '/api/v1.0/sysconfig/sys_file_add',
  sign: 'A24692D56511C6FE3CED1841A95135D3',
}

export default {
  PATH: PATH
}
